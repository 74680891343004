<template>
  <tarjeta-datos header :icon="stripedIcon">
    <template v-slot:titulo>
      {{ title }}
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        rounded
        class="ml-auto"
        small
        @click.stop="isEditing = true"
        v-if=" editable && (!isEditing || preventDefaultEditing)"
        >
        <v-icon left small>mdi-pencil</v-icon>
        Editar
        </v-btn>
      <template v-else-if="editable">
        <div class="d-flex" style="gap: 5px">
          <v-btn
            color="primary"
            class="ml-auto"
            small
            icon
            @click.stop="saveChanges(false)"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
          <v-btn
            color="primary"
            class="ml-auto"
            small
            icon
            @click.stop="saveChanges(true)"
            ><v-icon small>mdi-content-save</v-icon></v-btn
          >
        </div>
      </template>
    </template>
    <div v-if="!isEditing || preventDefaultEditing" class="text-h6 ma-4">
      {{ value }}
    </div>
    <div v-else class="ma-4">
      <v-text-field v-model="newValue" filled dense hide-details></v-text-field>
    </div>
  </tarjeta-datos>
</template>

<script>
export default {
  components: {
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
  },
  props: {
    value: String | Number,
    title: String,
    icon: String,
    editable: {
      type: Boolean,
      default: true,
    },
    preventDefaultEditing: {
      type: Boolean,
      default: false,
    },
    editing: { type: Boolean, default: false },
  },
  data() {
    return {
      newValue: this.value,
    };
  },
  computed: {
    stripedIcon() {
      return this.icon ? this.icon.replace("mdi-", "") : ''
    },
    isEditing: {
      get: function() {
        return this.editing;
      },
      set: function(v) {
        this.$emit("editing", v);
      },
    },
  },
  methods: {
    saveChanges(save) {
      if (save) {
        this.$emit("change", this.newValue);
      } else {
        this.newValue = this.value;
      }
      this.isEditing = false;
    },
  },
};
</script>

<style></style>
